@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */


@import "newCODEY.css";
@import "lib/_icons.css";

@layer base {

}