:root {
  --icon-unitarget: '\e634' ;
  --icon-unidesign: '\e681';
  --icon-uniredcross: '\e6b2';
  --icon-unimuscle: '\e6d0';
  --icon-unichecklist: '\e73e';
  --icon-unilab: '\e74a';
  --icon-unicloupics: '\e77f';
  --icon-unihtmlscreen: '\e792';
  --icon-unihtml: '\e793';
  --icon-uniradiotower: '\e7a5';
  --icon-unidrivetime: '\e7e0';
  --icon-unidrivecloud: '\e7e1';
  --icon-unidoubledrive: '\e7e3';
  --icon-unicheckdrive: '\e7f8';
  --icon-unicertificate: '\e80f';
  --icon-unidoctorsbag: '\e8ce';
  --icon-unitypescreen: '\e915';
  --icon-uniseedhouse: '\e97f';
  --icon-unikey: '\ea01';
  --icon-uniheartpage: '\ea65';
  --icon-unimedal: '\eaa5';
  --icon-unimultiscreen: '\ead0';
  --icon-unibackpages: '\ebd4';
  --icon-unifreeway: '\ebfe';
  --icon-unirobot: '\ec00';
  --icon-unishield: '\ec4f';
  --icon-uniloop: '\ed6e';
  --icon-unipagecode: '\edb7';
  --icon-heart-empty: '\e800';
  --icon-heart: '\e801';
  --icon-star: '\e802';
  --icon-spin5: '\e803';
  --icon-map: '\e804';
  --icon-check: '\e806';
  --icon-twitter: '\e808';
  --icon-codeyleaf: '\e809';
  --icon-mail: '\e80d';
  --icon-report: '\e902';
  --icon-turn: '\e903';
  --icon-tweet: '\e904';
  --icon-unlock: '\e905';
  --icon-cart: '\e900';
  --icon-phone: '\e901';
  --icon-share2: '\ea82';
  --icon-facebook: '\ea90';
  --icon-instagram: '\ea92';
  --icon-twitter1: '\ea96';
  --icon-vimeo2: '\eaa1';
  --icon-soundcloud: '\eac3';
  --icon-linkedin2: '\eaca';
  --icon-pinterest2: '\ead2';

}

@font-face {
  font-family: 'icomoon_codey';
  src:
    url('../fonts/icomoon_codey-2/fonts/icomoon_codey.woff2') format('woff2'),
    url('../fonts/icomoon_codey-2/fonts/icomoon_codey.ttf') format('truetype'),
    url('../fonts/icomoon_codey-2/fonts/icomoon_codey.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon_codey';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-unitarget::before {
  content: var( --icon-unitarget); 
}
.icon-unidesign::before {
  content: var( --icon-unidesign); 
}
.icon-uniredcross::before {
  content: var( --icon-uniredcross); 
}
.icon-unimuscle::before {
  content: var( --icon-unimuscle); 
}
.icon-unichecklist::before {
  content: var( --icon-unichecklist); 
}
.icon-unilab::before {
  content: var( --icon-unilab);
}
.icon-unicloupics::before {
  content: var( --icon-unicloupics); 
}
.icon-unihtmlscreen::before {
  content: var( --icon-unihtmlscreen); 
}
.icon-unihtml::before {
  content: var( --icon-unihtml);
}
.icon-uniradiotower::before {
  content: var( --icon-uniradiotower); 
}
.icon-unidrivetime::before {
    content: var( --icon-unidrivetime); 
}
.icon-unidrivecloud::before {
  content: var( --icon-unidrivecloud); 
}
.icon-unidoubledrive::before {
  content: var( --icon-unidoubledrive); 
}
.icon-unicheckdrive::before {
  content: var( --icon-unicheckdrive); 
}
.icon-unicertificate::before {
  content: var( --icon-unicertificate); 
}
.icon-unidoctorsbag::before {
  content: var( --icon-unidoctorsbag); 
}
.icon-unitypescreen::before {
  content: var( --icon-unitypescreen); 
}
.icon-uniseedhouse::before {
    content: var( --icon-uniseedhouse); 
}
.icon-unikey::before {
   content: var( --icon-unikey);
}
.icon-uniheartpage::before {
    content: var( --icon-uniheartpage); 
}
.icon-unimedal::before {
   content: var( --icon-unimedal); 
}
.icon-unimultiscreen::before {
  content: var( --icon-unimultiscreen); 
}
.icon-unibackpages::before {
    content: var( --icon-unibackpages); 
}
.icon-unifreeway::before {
    ontent: var( --icon-unifreeway); 
}
.icon-unirobot::before {
   content: var( --icon-unirobot); 
}
.icon-unishield::before {
  content: var( --icon-unishield); 
}
.icon-uniloop::before {
  content: var( --icon-uniloop);
}
.icon-unipagecode::before {
    content: var( --icon-unipagecode); 
}
.icon-heart-empty::before {
    content: var( --icon-heart-empty); 
}
.icon-heart::before {    
  content: var( --icon-heart); 
}
.icon-star::before {
  content: var(icon-star); 
}
.icon-spin5::before {    
  content: var( --icon-spin5); 
}
.icon-map::before {
  content: var(--icon-map); 
}
.icon-check::before {  
  content: var( --icon-check); 
}
.icon-twitter::before {
  content: var( --icon-twitter);
}
.icon-codeyleaf::before {
  content: var( --icon-codeyleaf); 
}
.icon-mail::before {
  content: var(icon-mail);
}
.icon-report::before {
  content: var( --icon-report);
}
.icon-turn::before {
  content: var(icon-turn); 
}
.icon-tweet::before {    
  content: var( --icon-tweet); 
}
.icon-unlock::before {
  content: var( --icon-unlock); 
}
.icon-cart::before {
  content: var(icon-cart);
}
.icon-phone::before {  
  content: var( --icon-phone); 
}
.icon-share2::before {
   content: var( --icon-share2); 
}
.icon-facebook::before {
   content: var( --icon-facebook); 
}
.icon-instagram::before {
  content: var( --icon-instagram); 
}
.icon-twitter1::before {
  content: var( --icon-twitter1); 
}
.icon-vimeo2::before {
  content: var( --icon-vimeo2); 
}
.icon-soundcloud::before {
  content: var( --icon-soundcloud); 
}
.icon-linkedin2::before {
  content: var( --icon-linkedin2); 
}
.icon-pinterest2::before {
  content: var( --icon-pinterest2); 
}

